export const react_domain = `${window.location.origin}`;
// export const react_domain = `http://localhost:3000`;
// export const react_domain = `https://software.allrobosolution.com`;


export const smartAlogUrl = `https://newpenal.pandpinfotech.com/backend/`;
// export const smartAlogUrl = "http://localhost:7700/";


export const base_url = `${window.location.origin}/backend/`
// export const base_url = "https://newpenal.pandpinfotech.com/backend/";
// export const base_url = "http://localhost:7700/";
// export const base_url = `https://software.allrobosolution.com/backend/`;

// export const broker_backend = `http://localhost:8000/broker-signals`;
export const broker_backend = `${window.location.origin}/signal/broker-signals`;
      





