/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import Content from "../../../Components/Dashboard/Content/Content";
import { useDispatch } from "react-redux";
import { GetAdminTransection } from "../../../ReduxStore/Slice/Superadmin/SuperAdminSlice";

const ApiCreateInfo = () => {
  const dispatch = useDispatch();
  const [amountType, setAmountType] = useState(0);
  const [amountRange, setAmountRange] = useState("");

  const [transectionData, setTransectionData] = useState([]);

  const GetTransectionData = async () => {
    const data = {
      amountType: amountType,
      amountRange: amountRange,
    };
    const res = await dispatch(GetAdminTransection(data)).unwrap();
    if (res.data) {
      setTransectionData(res.data);
    }
  };

  console.log("transectionData", transectionData);

  return (
    <>
      <Content Page_title="Received Amount" button_status={false}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            marginBottom: "1.5rem",
            alignItems: "center",
          }}
        >
          <div
            className="mb-4"
            style={{
              flex: "1 1 300px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h6 style={{ marginBottom: "10px", fontWeight: "bold" }}>
              Amount Type
            </h6>

            <select
              className="form-control p-2 rounded"
              style={{
                borderRadius: "6px",
                border: "1px solid #ccc",
                fontSize: "16px",
              }}
              onChange={(e) => setAmountType(e.target.value)}
              value={amountType}
            >
              <option value="0">Received</option>
              <option value="1">Service Charged</option>
            </select>
          </div>

          <div
            className="mb-4"
            style={{
              flex: "1 1 200px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h6 style={{ marginBottom: "10px", fontWeight: "bold" }}>
              Amount Range
            </h6>
            <input
              className="form-control"
              type="text"
              value={amountRange}
              onChange={(e) => setAmountRange(e.target.value)}
            />
          </div>

          <div
            className="mb-4"
            style={{
              flex: "1 1 200px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h6 style={{ marginBottom: "30px", fontWeight: "bold" }}></h6>

            <button
              className="btn btn-primary"
              onClick={(e) => GetTransectionData()}
            >
              Search
            </button>
          </div>
        </div>

        {transectionData.length > 0 && (
  <div className="table-responsive">
    <table className="table table-bordered" style={{ color: "black" }}>
      <thead style={{ color: "black" }}>
        <tr style={{ color: "black" }}>
          <th style={{ color: "black" }}>panel_name</th>
          <th style={{ color: "black" }}>User Name</th>
          <th style={{ color: "black" }}>Total Service Charge</th>
          <th style={{ color: "black" }}>Total Received</th>
        </tr>
      </thead>
      <tbody style={{ color: "black" }}>
        {transectionData[0].map((item, index) => (
          <tr key={index}>
            <td style={{ color: "black" }}>{item.panel_name}</td>
            <td style={{ color: "black" }}>{item.UserName}</td>
            <td style={{ color: "black" }}>{item.totalServiceCharge}</td>
            <td style={{ color: "black" }}>{item.totalReceived}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

      </Content>
    </>
  );
};

export default ApiCreateInfo;
